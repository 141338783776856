:global(.ui.grid).container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  margin: auto;
  font-size: 1.1rem;
  font-weight: 600;
}
