.container {
  margin: auto !important;
  border-radius: 10px;
  background-color: white;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  left: 50%;
}

.container::before {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  background: white;
  left: -40px;
  top: -40px;
  border-radius: 50px;
  z-index: -1;
}

.container::after {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  background: rgb(221, 132, 112);
  right: -40px;
  bottom: -40px;
  border-radius: 50px;
  z-index: -1;
}

.loginContainer {
  height: 350px;
}
.header {
  margin-top: 20px;
}
.line {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
