.grid {
  height: 100vh;
}

.loader {
  margin: auto;
  border: 5px solid #eaf0f6;
  border-radius: 50%;
  border-top: 5px solid rgb(221, 132, 112);
  width: 200px;
  height: 200px;
  animation: spinner 3s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes reverse-spinner {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.img {
  animation: reverse-spinner 3s linear infinite;
}
