.detail {
  height: 100px;
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #ccc;
  background: white;
  padding: 0 10px;
  justify-content: space-between;
  position: relative;
}
.detail:last-child {
  margin-bottom: 60px;
}
.plusContainer {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: fixed;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid rgb(221, 132, 112);
  background-color: rgb(235, 203, 196);
  display: flex;
  justify-content: center;
  align-items: center;
}
i.plus {
  margin: 0;
}
.picContainer {
  width: 100px;
  height: 50px;
  display: inline-block;
  text-align: center;
}
.time {
  font-weight: bold;
  margin-right: 20px;
  font-size: 24px;
}
.pic {
  margin: auto;
}
.name {
  display: inline-block;
  padding-left: 5px;
  margin-top: 4px;
}
.list {
  padding: 20px;
}
.new {
  display: inline-block;
  transform: rotate(45deg);
  margin-left: 17px;
  margin-top: 9px;
}
