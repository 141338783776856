:global(.ui.grid)>.gridRow {
    border-radius: 0.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
}
:global(.ui.grid)>.booking {
    background-color: rgb(221,132,112);
    border-radius: 0.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
}
:global(.ui.grid)>.booking :global(.ui.button) {
    background-color: rgb(221,132,112);
    color: aliceblue;
}
.gridRow > :global(.column .value) {
    font-size: 1.3rem;
    font-family: 'Noto Sans TC', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
}
.gridRow > :global(.column .label) {
    font-weight: 300;
}
.card {
    color: rgb(221,132,112);
}
:global(.ui.padded.grid:not(.vertically):not(.horizontally)).summaryInfo{
    margin-top: -47px !important;
    padding-top: 27px;
}
.summaryInfo {
    border: 1px solid #fff;
    border-radius: 13.5px;
    background-color: rgb(221,132,112,.7);
    color: #fff;
}
.image {
    z-index: 1;
    border: 0.3rem solid #fff;
}
.label {
    font-size: 1.1rem;
    font-weight: 600;
}
.balance {
    font-size: 2rem;
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 18px;
}
.record {
    display: flex;
    justify-content: space-between;
}
.record > div:nth-child(1) {
    justify-content: center;
    margin: 0;
}
.record > div:nth-child(2) {
    align-items: end;
}
